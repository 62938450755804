const PasswordRules = (configurations) => {
  const config = {
    minLength: 8,
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: true,
    ...(configurations || {}),
  };

  return [
    (v) => !!v || "Senha é obrigatória",
    (v) =>
      (config.uppercase && /[A-Z]/.test(v)) ||
      "Senha deve conter pelo menos uma letra maiúscula",
    (v) =>
      (config.lowercase && /[a-z]/.test(v)) ||
      "Senha deve conter pelo menos uma letra minúscula",
    (v) =>
      (config.numbers && /\d/.test(v)) ||
      "Senha deve conter pelo menos um número",
    // (v) => {
    //   const symbols = /[!@#$%^&*(),.?:{}|+<>=-]/g;
    //   return (
    //     (config.symbols && symbols.test(v)) ||
    //     "Senha deve conter pelo menos um símbolo"
    //   );
    // },
    (v) =>
      v.length >= config.minLength ||
      `Senha deve ter no mínimo ${config.minLength} caracteres`,
  ];
};

export default PasswordRules;
